import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const getFirstThreePostTitleQuery = graphql`
query {
    allWpPost (
        sort: { fields: [date], order: DESC }
        limit: 3
      ) {
      nodes {
        id
        date (formatString: "MMMM DD, YYYY")
        uri
        title
      }
    }
}
`
const InfoNavBlockFooter = () => {
    const data = useStaticQuery(getFirstThreePostTitleQuery)
    const posts = data.allWpPost.nodes
    return (
        <div className="mt-5 font-opensans bg-darkcharcoal-default text-white">
            <div className="px-6 grid justify-items-start grid-cols-1 md:gap-8 md:grid-cols-3">
                <div className="my-4">
                    <h4>
                        Contact Us
                    </h4>
                    <span className="text-xs">
                        WinDecor Window Coverings
                        <br />
                        (403) 775-9097
                        <br />
                        Hours: Monday-Friday - 9a.m.-7p.m.
                        <br />
                        Saturday - 9a.m.-6p.m.
                    <br />
                    Sunday - 11a.m.-5p.m.
                    </span>
                </div>
                <div className="my-4">
                    <h4>
                        About Us
                    </h4>
                    <span className="text-sm">
                    <Link to="/blog">
                        Blog
                    </Link>
                    <br />
                    <Link to="/about-us">
                        About Us
                    </Link>
                    <br />
                    <Link to="/scheduleconsult">
                        Contact Us
                    </Link>
                    <br />
                    <Link to="/join-us">
                        Join Us
                    </Link>
                    </span>
                </div>
                <div className="my-4">
                    <h4>
                        Recent Posts
                    </h4>
                    <span className="text-sm">
                    {
                        posts.map( post => (
                            <div className="mb-2" key={post.id}>
                                <Link
                                    to={`${post.uri}`}
                                    > 
                                        { '>  '.concat (post.title) }
                                </Link>
                            </div>
                        ))
                    }
                    </span>
                </div>
            </div>
        </div>
    )
}

export default InfoNavBlockFooter
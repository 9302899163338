import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from 'gatsby-plugin-wpgraphql-seo';

const getPostArchiveQuery = graphql`
query GET_POSTS($ids: [String]) {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpPost(filter: {id: {in: $ids}}) {
      nodes {
        id
        uri
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }  
`
const getFeaturedImg = (post) => {
  const image = post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  const altText = post.featuredImage?.node?.altText || ``
  return (
    <GatsbyImage 
      image={image} 
      alt={altText} />
  );

}

const BlogArchive = () => {
    const data = useStaticQuery(getPostArchiveQuery)
    const posts = data.allWpPost.nodes
    
    return (
      <div className="container mx-auto font-opensans mt-5 px-6">
        <Seo 
            title="Blog | WinDecor Window Coverings | Calgary"
            postSchema={JSON.parse(data.wp.seo.contentTypes.post.schema.raw)}
        />
                {posts.map(post => (
                    <article key={post.id}>
                      <Link to={post.uri} >
                        <h1 className="underline text-xl" 
                        dangerouslySetInnerHTML={{ __html: post.title }} />
                        {getFeaturedImg(post)} 
                      </Link>
                      <div 
                        className="mt-4"
                        dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                    </article>
                ))
                }
        </div>
    )
}

export default BlogArchive
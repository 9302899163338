import React from "react"
import { Link } from "gatsby"

const Footer = () => {
    return (
        <footer className="bg-white shadow text-darkcharcoal-default font-opensans">
            <div className='flex justify-center items-center flex-wrap'>
                <div className="mt-2">
                    Schedule FREE In-Home Consultation
                </div>
                <div className="px-6">
                    <button className="p-2 mt-3 mb-3 border-2 border-primary-default rounded bg-primary-default hover:bg-primary-darker hover:text-white font-semibold text-base shadow-sm transition ease-in duration-500">
                        <Link to="/scheduleconsult">
                            Schedule Now
                        </Link>
                    </button>
                </div>
            </div>
        </footer>
    );
}

export default Footer
import React from "react"
import BlogArchive from "../components/blogarchive"
import Layout from "../components/layout"

const Blog = () => {
    return (
        <Layout>
            <BlogArchive />
        </Layout>
    )
}

export default Blog
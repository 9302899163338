import React, { useState } from 'react';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <header className='sticky top-0 bg-white shadow z-10 text-darkcharcoal-default font-opensans'>
      <nav className='flex items-center flex-wrap p-2 '>
        <div className='inline-flex items-center mr-4'>
          <div className="hidden md:inline-flex">
            <Link to='/'>
              <StaticImage 
                  src="../images/WindecorLogo.jpg" 
                  alt=""
                  loading="eager"
                  width={180}
                  height={60}
              />
            </Link>
          </div>
          <div className="md:hidden">
            <StaticImage 
                  src="../images/Windecor-Favicon.png" 
                  alt=""
                  loading="eager"
                  width={40}
                  height={40}
              />
          </div>
          <div className="md:hidden px-2">
            <Link to='/'>
              <span className='text-base font-bold tracking-wide'>
                {siteTitle}
              </span>
            </Link>
          </div>
        </div>
        <div className='inline-flex items-center mr-4'>
            <span className='text-base font-semibold'>
                403.775.9097
            </span>
        </div>
        <button
          className='inline-flex p-2 mr-4 rounded lg:hidden ml-auto outline-none'
          onClick={handleClick}
        >
          <svg
            className='w-6 h-6'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path 
              className={`${
                active ? 'hidden' : ''}`}
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M4 6h16M4 12h16M4 18h16'
            />

            <path 
              className={`${
                active ? '' : 'hidden'}`}
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M6 18L18 6M6 6l12 12" 
            />
          </svg>
        </button>
        {/*Note that in this div we will use a ternary operator to decide whether or not to display the content of the div  */}
        <div
          className={`${
            active ? '' : 'hidden'
          }   w-full lg:inline-flex lg:flex-grow lg:w-auto`} >
          <div className='lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start  flex flex-col lg:h-auto'>
            <Link to='/' className='lg:inline-flex lg:w-auto w-full m-3 font-bold items-center justify-center lg:border-b-2 lg:border-primary-default lg:hover:border-white
            underline 
            hover:no-underline
            lg:no-underline'>
                Home
            </Link>
            <Link to='/products/blinds' className='lg:inline-flex lg:w-auto w-full m-3 font-bold items-center justify-center lg:border-b-2 lg:border-primary-default lg:hover:border-white
            underline 
            hover:no-underline
            lg:no-underline'>
                Blinds
            </Link>
            <Link to='/products/shades' className='lg:inline-flex lg:w-auto w-full m-3 font-bold items-center justify-center lg:border-b-2 lg:border-primary-default lg:hover:border-white
            underline 
            hover:no-underline
            lg:no-underline'
            >
                Shades
            </Link>
            <Link to='/products/shutters' className='lg:inline-flex lg:w-auto w-full m-3 font-bold items-center justify-center lg:border-b-2 lg:border-primary-default lg:hover:border-white
            underline 
            hover:no-underline
            lg:no-underline'>
                Shutters
            </Link>
            <Link to='/scheduleconsult' className='lg:inline-flex lg:w-auto w-full m-3 font-bold items-center justify-center lg:border-b-2 lg:border-primary-default lg:hover:border-white
            underline 
            hover:no-underline
            lg:no-underline'>
                Schedule Free Appointment
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header